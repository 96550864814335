// debugger
.lhstock-hidden {
  opacity: 0;
}
// font lhstock: 字号规范不太对

.a-font-brand {
  font-family: $__font_ubuntu;
}
.a-font-h1-brand {
  font-family: $__font_ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 69px;
}
.a-font-h1-ali {
  font-family: $__font_ali;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 84px;
}
.a-font-h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
}
.a-font-h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 39px;
}

.a-font-h4-ali {
  font-family: $__font_ali;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 34px;
}

.a-font-h4-brand {
  font-family: $__font_ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
.a-font-h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}
.a-font-h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.a-font-h5-ali {
  font-family: $__font_ali;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  opacity: 0.5;
}
.a-font-h5-brand {
  font-family: $__font_ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  opacity: 0.5;
}
.a-font-h6 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.a-font-h7 {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}
.a-font-h8 {
  font-size: 11px;
  line-height: 15px;
}
.a-font-h8-brand {
  font-family: $__font_ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

// variables

// 布局类
.a-flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.a-flex-column-center-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.a-flex-column-box {
  @extend .a-flex-box;
  flex-direction: column;
}

// 组件
.a-logo {
  &__small {
    width: 44px;
    height: 44px;
  }

  &__big {
    width: 88px;
    height: 88px;
  }
}

.pc {
  .a-page-content {
    height: 100%;
    margin: 0 auto;
    max-width: $__pc_max_width;
    width: 100%;
    box-sizing: border-box;

    @media screen and (min-width: $__mobile_width) {
      //padding: 0 20%;
    }
  }
}

@media screen and (max-width: $__mobile_width) {
  .a-page-content {
    height: 100%;
    padding: 0;
  }
}
