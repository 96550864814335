@import '@/theme/variables.scss';
.a-module-mini-program {
  &::before {
    background-image: url('https://cdn.tablera.com/md5/d020709e1b72c9aef24122c53d00d50b.png/q80.webp');
    background-size: 650px 650px;
    background-position: center -50px;
    background-repeat: no-repeat;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    filter: blur(100px);
    z-index: -1;
  }
  .page-module-img {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  &-img {
    margin: 0 auto;
  }

  .a-photo__img {
    max-width: 90%;
    margin: 0 auto;
  }
}
.mobile {
  .a-module-mini-program {
    &::before {
      background-size: 325px 325px;
    }
  }
}
@media screen and (max-width: $__mobile_width) {
  .a-module-mini-program {
    .page-module-img {
      padding-top: 12px;
      padding-bottom: 30px;
      margin: 0;
    }
    &::before {
      display: none;
    }
    background-image: url('https://cdn.tablera.com/md5/6b6cc0c3566087ac2dc988306994bdd2.png/q80.webp');
    background-size: 212px 212px;
    background-position: center 100px;
    background-repeat: no-repeat;
  }
}
.pc {
  .module-mini-program {
    .page-module-img {
      max-width: 1020px;
    }
  }
}
