@import '@/theme/variables.scss';

.module-customer {
  max-width: 1400px;
  margin: 0 auto;
  // max-width: 100vw;
  &.page-module {
    margin-bottom: 0;
    padding: 0 20px;
  }

  .module-customer-logo {
    width: 100px;
    height: 100px;
  }

  .module-customer-logo-group {
    margin-top: 40px;
    margin-bottom: 120px;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .module-customer-logo-wrap {
    width: 170px;
    height: 110px;
    border: 1px solid #222;
    border-radius: 12px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  @media screen and (max-width: $__mobile_width) {
    .module-customer-logo-group {
      margin-top: 18px;
      margin-bottom: 50px;
    }

    .module-customer-logo-wrap {
      margin-right: 8px;
      margin-bottom: 8px;
      width: 106px;
      height: 90px;
    }

    .module-customer-logo {
      width: 73px;
      height: 73px;
    }
  }
}
