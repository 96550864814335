@import '@/theme/variables.scss';
.a-photo {
  position: relative;
  box-sizing: border-box;

  &__img {
    width: 100%;
  }
}
@media screen and (max-width: $__mobile_width) {
  .a-photo {
  }
}
