@import '@/theme/variables.scss';
.a-brand-name {
  display: flex;
  align-items: center;
}
@media screen and (min-width: $__mobile_width) {
  .mobile {
    .a-brand-name {
      svg {
        display: none;
      }
      &-1 {
      }
      &-2 {
        width: 134px;
        height: 32px;
        background-image: url('~@/images/brand-logo/property28.svg');
      }
      &-3 {
        width: 134px;
        height: 32px;
        background-image: url('~@/images/brand-logo/property28.svg');
        //width: 97px;
        //height: 23px;
        //background-image: url('~@/images/brand-logo/property20.svg');
      }
      &-4 {
        width: 76px;
        height: 18px;
        background-image: url('~@/images/brand-logo/property16.svg');
      }
    }
  }
}
