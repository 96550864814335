@import 'variables';
html {
  font-size: 62.5%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}
body {
  background-color: $__black1;
  margin: 0;
  color: $__white;
  overflow-x: hidden;
}
:root {
  overflow-x: hidden;
}

img {
  display: block;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  &:hover {
    filter: brightness(0.9);
  }
}

p,
button {
  margin: 0;
}

body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    font-weight: 400;
  }

  h1 {
    line-height: 34px;
    font-size: 28px;
    font-weight: bold;
  }

  h2 {
    line-height: 28px;
    font-size: 24px;
  }

  h3 {
    line-height: 26px;
    font-size: 22px;
  }

  h4 {
    line-height: 24px;
    font-size: 20px;
  }

  h5 {
    line-height: 22px;
    font-size: 18px;
  }

  h6 {
    line-height: 19px;
    font-size: 16px;
  }
}
