@import '@/theme/variables.scss';

.module-contact {
  .module-contact-btn {
    bottom: 120px;
    right: 18px;
    min-width: 178px;
    padding: 0 31px;
    height: 48px;
    border-radius: 24px;
    background-color: #005caf;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    font-family: 'ubuntu';
    box-sizing: border-box;
    z-index: 11;
    cursor: pointer;

    svg {
      margin-right: 10px;
    }
  }

  .module-contact-popover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    position: fixed;
  }

  .module-contact-popover-content {
    border-radius: 10px;
    padding: 24px 30px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    right: 18px;
    bottom: 184px;
    background-color: #222;
    position: fixed;
    animation: PcPopoverShow 300ms;
    transform-origin: center bottom;
    box-sizing: border-box;

    &::after {
      content: '';
      bottom: -5px;
      right: 85px;
      width: 10px;
      height: 10px;
      background-color: #222;
      position: absolute;
      transform: rotate(45deg);
    }
  }

  .module-contact-line {
    margin-top: 10px;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    a {
      color: #fff;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
  }

  .module-contact-tag {
    height: 20px;
    font-size: 11px;
    font-weight: 400;
    border-radius: 10px;
    border: 1px solid #fff;
    margin-left: 12px;
    padding: 0 8px;
    display: none;
    align-items: center;
    justify-content: center;
    transform: translateY(2px);
    cursor: pointer;
    white-space: nowrap;
  }

  @media screen and (max-width: $__mobile_width) {
    .module-contact-btn {
      left: 50%;
      min-width: 170px;
      font-size: 16px;
      padding: 0 35px;
      line-height: 18.38px;
      bottom: calc(env(safe-area-inset-bottom) + 30px);
      right: unset;
      transform: translateX(-50%);
    }

    .module-contact-popover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .module-contact-popover-content {
      top: 50%;
      left: 50%;
      max-height: 152px;
      font-size: 20px;
      line-height: 28px;
      width: calc(100vw - 50px);
      transform: translate(-50%, -50%);
      animation: MobilePopoverShow 300ms;

      &::after {
        display: none;
      }
    }

    .module-contact-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
    }

    .module-contact-tag {
      display: inline-flex;
    }
  }
}

@keyframes PcPopoverShow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes MobilePopoverShow {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
