@import '@/theme/variables.scss';
.module-introducer {
  justify-content: space-between;
  margin: 0 50px;
  height: 160px;
  margin-bottom: 100px;
  display: block;
  &.page-module {
    display: block;
    border-radius: 10px;
  }

  &__content {
    height: 100%;
    border: 0.5px solid #333333;
    border-radius: 10px;
    flex: 1 1 auto;
    &__left {
      padding-left: 56px;
      &__top {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .a-brand-name {
          margin: 0 12px;
        }
      }
      &__bottom {
        color: $__black6;
      }
    }
    &__right {
      padding-right: 30px;
      flex: 1 1 auto;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      background-image: url('https://cdn.tablera.com/md5/678446eea48662a1b4cd492dab679b23.png/q80.webp');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position-x: right;
      background-position-y: top;
    }
  }
}

@media screen and (max-width: $__mobile_width) {
  .module-introducer {
    margin: 0 18px;
    height: 100px;
    background-image: url('https://cdn.tablera.com/md5/bccb85798193eef5e6d24bf7596854e5.png/q80.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position-x: right;
    background-position-y: top;
    &__content {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      &__left {
        padding: 0;
        margin-bottom: 8px;

        align-items: center;
        display: flex;
        flex-direction: column;
        &__top {
          align-items: center;
          display: flex;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 2px;
          .a-brand-name {
            margin: 0 4px;
          }
        }
        &__bottom {
          p {
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #999999;
          }
        }
      }
      &__right {
        padding: 0;
        background-image: none;
        display: block;
        flex: 0 0 auto;
        height: auto;
      }
    }
  }
}
