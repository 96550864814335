@font-face {
  font-family: 'Ubuntu';
  src: url('./Ubuntu-Medium.ttf');
  src: url('./Ubuntu-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Alibaba PuHuiTi 2.0';
  src: url('./AlibabaPuHuiTi-2-45-Light.ttf');
  src: url('./AlibabaPuHuiTi-2-45-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
