@import "@/theme/variables.scss";
@import "@/theme/normal.scss";
.a-button {
  box-sizing: border-box;
  border: 0;
  border-radius: 44px;
  background-color: $__blue_btn;
  color: $__white;
  cursor: pointer;
  user-select: none;
  @include __a-font-h3;
  &__big {
    border-radius: 86px;
    height: 60px;
    padding: 0 38px;
    p {
      @include __a-font-h3;
    }
  }
  &__middle {
    height: 34px;
    padding: 0 18px;
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }
  &__small {
    height: 24px;
    padding: 0 12px;
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
    }
  }
}
