@import '@/theme/variables.scss';

.a-module-footer {
  background: #000000;
  border-top: 0.5px solid #333333;
}
.module-footer {
  width: 100%;
  &.page-module {
    margin-bottom: 0;
    padding: 0 20px;
  }

  &__content {
    width: 100%;
    height: 90px;
    justify-content: space-between;
    &__left {
      &__top {
        margin-bottom: 6px;
        .a-brand-name {
          margin-right: 7px;
        }
        .des-end {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        justify-content: flex-start;
      }
      &__bottom {
        @include __a-font-h8;
        color: $__black6;
      }
    }
    &__right {
      @include __a-font-h6;
      &__slicer {
        color: $__black9;
      }
    }
  }
}

@media screen and (max-width: $__mobile_width) {
  .mobile {
    .a-module-footer {
    }
    .module-footer {
      &.page-module {
        padding: 0 16px;
      }
      &__content {
        height: 82px;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-start;
        &__left {
          max-width: calc(100vw - 32px);
          &__top {
            margin-bottom: 2px;
          }
          &__bottom {
            line-height: 1rem;
            font-size: 1rem;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            p {
              font-size: 20px;
              line-height: 28px;
              transform: scale(.5, .5);
              transform-origin: left top;
              width: 200vw;
            }
          }
          .des-end {
            line-height: 1rem;
            font-size: 1rem;
          }
        }
        &__right {
          margin-top: 12px;
          margin-bottom: 9px;
          p,
          span {
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
          }
        }
      }
    }
  }
}
