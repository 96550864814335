@import '@/theme/variables.scss';

.store-card-wrapper {
  box-sizing: border-box;
}

.store-card {
  box-sizing: border-box;
  //width: 696px;
  //height: 756px;
  flex: 1 1 100px;
  background: linear-gradient(180deg, #181818 0%, #090909 100%);
  border: 1px solid #222;
  border-radius: 10px;
  display: flex;

  & + & {
    margin-top: 20px;
  }

  &-header {
    box-sizing: border-box;
    padding: 40px 70px 40px 50px;
    //height: 270px;

    &-honor {
      width: 118px;
      height: 30px;
    }

    &-name {
      margin-left: 18px;
      @include __a-font-h3;
    }

    pre {
      color: #666;
      word-break: break-all;
      white-space: pre-wrap;
      font-size: 18px;
      line-height: 25.2px;
      font-weight: 400;
    }

    &-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      &__title {
        &__left {
          margin-right: 12px;
          display: flex;
          align-items: center;
          img {
            width: 78px;
            height: 78px;
          }
        }
      }
    }
    &-line {
      height: 1px;
      background-color: $__black3;
      margin-bottom: 20px;
    }
    &-bottom {
      @include __a-font-h6;
      color: $__black6;
    }
  }
  &-content {
    width: 530px;
    margin-right: 20px;
    padding-top: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    &__store {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1000px) {
  .module-client {
    padding-top: 12px !important;
  }
  .store-card {
    max-width: 100%;
    margin: 0 18px;
    display: block;
    border-radius: 6px;

    &-header {
      padding: 20px;

      &-line {
        margin-bottom: 14px;
      }

      &-top {
        margin-bottom: 14px;
        flex-direction: column;

        &__title__left {
          flex-direction: column;
          margin-right: 0;

          img {
            width: 50px;
            height: 50px;
          }
        }
      }

      &-honor {
        margin-top: 6px;
        height: 16px;
        width: unset;
      }
    }

    &-content {
      padding-top: 0;
      width: 100%;
      margin-right: 0;

      &__store__img {
        padding: 0 20px;
      }

      img {
        width: 100% !important;
        height: unset !important;
      }
    }
  }

  .store-card + .store-card {
    margin-top: 17px;
  }
}

@media screen and (max-width: 800px) {
  .store-card {
    pre {
      font-size: 18px;
      line-height: 23px;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 600px) {
  .module-client {
    padding-top: 12px !important;
  }
  .store-card {
    max-width: 100%;
    margin: 0 18px;
    display: block;
    border-radius: 6px;

    &-header {
      padding: 20px;

      &-line {
        margin-bottom: 14px;
      }

      &-top {
        margin-bottom: 14px;
        flex-direction: column;

        &__title__left {
          flex-direction: column;
          margin-right: 0;

          img {
            width: 50px;
            height: 50px;
          }
        }
      }

      &-name {
        margin-top: 6px;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        margin-left: 0;
      }

      &-honor {
        margin-top: 6px;
        height: 16px;
        width: unset;
      }

      pre {
        font-size: 12px;
        line-height: 16.8px;
        font-weight: 400;
      }
    }

    &-content {
      padding-top: 0;
      width: 100%;
      margin-right: 0;

      &__store__img {
        padding: 0 20px;
      }

      img {
        width: 100% !important;
        height: unset !important;
      }
    }
  }

  .store-card + .store-card {
    margin-top: 17px;
  }
}
