$__mobile_width: 700px;
$__pc_max_width: 1280px;
$__pc_min_width: 1208px;
$__pc_width: 1280px;
$__white: #ffffff;

$__black: #000000;
$__black1: #111111;
$__black2: #222222;
$__black3: #333333;
$__black6: #666666;
$__black9: #999999;

$__nav_height: 56px;
$__mobile_nav_height: 45px;

$__blue: #48C6ED;
$__yellow: #F1C151;
$__green: #3CE09B;
$__red: #E01D5A;
$__blue_btn: #005CAF;

// font
$__base-font-family: system-ui;
@function ff($fontFamily) {
  @return $fontFamily, $__base-font-family
}
$__font_ubuntu: ff('Ubuntu');

$__font_ali: ff('Alibaba PuHuiTi 2.0');

@mixin __a-font-h1-brand {
  font-family: $__font_ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 69px;
}
@mixin __a-font-h1-ali {
  font-family: $__font_ali;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 84px;
}
@mixin __a-font-h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
}
@mixin __a-font-h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 39px;
}

@mixin __a-font-h4-ali {
  font-family: $__font_ali;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 34px;
}

@mixin __a-font-h4-brand {
  font-family: $__font_ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
@mixin __a-font-h4 {
  font-weight: 300;
  font-size: 24px;
  line-height: 34px;
}
@mixin __a-font-h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
@mixin __a-font-h5-ali {
  font-family: $__font_ali;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
}
@mixin __a-font-h5-brand {
  font-family: $__font_ubuntu;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
@mixin __a-font-h6 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
@mixin __a-font-h7 {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}
@mixin __a-font-h8 {
  font-size: 11px;
  line-height: 15px;
}
@mixin __a-font-h8-brand {
  font-family: $__font_ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

// variables

// 布局类
@mixin __a-flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin __a-flex-column-center-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@mixin __a-flex-column-box {
  @include __a-flex-box;
  flex-direction: column;
}

