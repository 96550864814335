@import '@/theme/variables.scss';

.module-price {
  width: 100%;
  &.page-module {
    margin-bottom: 0;
    padding: 0 20px;
  }

  .module-price-wrap {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 140px;
    display: flex;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
  }

  .module-price-normal,
  .module-price-vip {
    flex: 1;
    padding: 60px 0 46px;
    text-align: center;
  }

  .module-price-vip {
    border-left: 1px solid #333;
  }

  .module-price-title {
    font-size: 48px;
    font-weight: 600;
    line-height: 67.2px;
  }

  .module-price-price {
    height: 51px;
    margin-top: 10px;
    font-size: 60px;
    line-height: 51px;
    color: #f1c151;
    font-family: 'Helvetica Neue';
  }

  .module-price-unit {
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    margin-left: 7px;
    line-height: 33.6px;
  }

  .module-price-divider {
    width: 208px;
    height: 1px;
    margin: 30px auto;
    background-color: #333;
  }

  .module-price-info {
    color: #999;
    font-size: 24px;
    font-weight: 600;
    line-height: 33.6px;
  }

  .module-price-info-item + .module-price-info-item {
    margin-top: 10px;
  }

  .module-price-vip {
    .module-price-price {
      color: #48c6ed;
      font-size: 32px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: $__mobile_width) {
    .module-price-wrap {
      margin-top: 12px;
      margin-bottom: 50px;
      border: none;
      display: block;
    }

    .module-price-normal,
    .module-price-vip {
      border: 1px solid #333;
      padding: 24px 0;
      border-radius: 10px;
    }

    .module-price-title {
      font-size: 24px;
      line-height: 33.6px;
    }

    .module-price-price {
      margin-top: 6px;
      font-size: 32px;
    }

    .module-price-vip {
      margin-top: 10px;

      .module-price-price {
        font-size: 18px;
        height: unset;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .module-price-divider {
      width: 100px;
      margin: 14px auto;
    }

    .module-price-info {
      font-size: 14px;
      line-height: 19.6px;
    }

    .module-price-info-item + .module-price-info-item {
      margin-top: 5px;
    }
  }
}
