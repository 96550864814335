@import '@/theme/variables.scss';

.a-section {
  //min-height: 80vh;
  &:nth-child(3) {
    display: flex;
    justify-content: flex-end;
  }
  &-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      color: #ffffff;
      &__line {
        width: 20px;
        height: 1px;
        background-color: $__black9;
      }
      &__title {
        @include __a-font-h2;
      }
    }
    &__des {
      @include __a-font-h4;
      color: #ffffff;
    }
  }

  .page-module {
    box-sizing: border-box;
    @include __a-flex-column-center-box;
  }
}
@media screen and (min-width: $__mobile_width) {
  .c-global-nav {
    max-width: 1512px;
    margin: 0 auto;
  }
  .page-module {
    margin-bottom: 134px;
    .page-module-img {
      //margin: 0 10%;
    }
  }
}
@media screen and (max-width: $__mobile_width) {
  .a-section {
    &:nth-child(3) {
      display: flex;
      justify-content: center;
    }
    &-header {
      &__title {
        &__line {
          width: 10px;
        }
        &__title {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
        }
      }
      &__des {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
  .a-page-content {
    min-width: 375px;
    margin: 0 auto;
  }
  .page-module {
    .page-module-img {
      margin: 0 20px;
    }
    &.module-register {
      margin-bottom: 35px;
    }
    &.module-mini-program {
      margin-bottom: 35px;
    }
    &.module-deposit {
      margin-bottom: 35px;
    }
    &.module-client {
      margin-bottom: 35px;
    }
    &.module-introducer {
      margin-bottom: 30px;
    }
  }
}

@media screen and (min-width: $__mobile_width) {
  .pc {
    .a-module-footer {
      .a-page-content {
        max-width: 1512px;
      }
    }
  }
}
