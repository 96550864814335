@import '@/theme/variables.scss';

.module-register {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background: url('~@/images/logo.svg') no-repeat right calc(100vw * (685 / 1145) * 5 / 9 / 10),
    url('https://cdn.tablera.com/md5/4f2ed3842e9cf779151476d7ad13e895.png/q80.webp') no-repeat left top;
  background-size: calc(100vw * (685 / 1145) * 5 / 9) calc(100vw * (685 / 1145) * 5 / 9),
    100% calc(100vw * (685 / 1145));
  @media screen and (min-width: $__mobile_width) {
    background: url('~@/images/logo.svg') no-repeat right calc(100vw * (220 / 350) * 5 / 9 / 10),
      url('https://cdn.tablera.com/md5/4f2ed3842e9cf779151476d7ad13e895.png/q80.webp') no-repeat left top;
    background-size: calc(100vw * (220 / 350) * 5 / 9) calc(100vw * (220 / 350) * 5 / 9), 100% calc(100vw * (220 / 350));
  }
  &-shadow {
    position: absolute;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, #111111 62%, #111111);
    backdrop-filter: blur(2.48016px);
    //height: calc(100vw * (685 / 1145));
    height: 100%;

    @media screen and (min-width: $__mobile_width) {
      //height: calc(100vw * (220 / 350));
      height: calc(100% + 3px);
    }
  }

  &-rectangle {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    @media screen and (min-width: $__mobile_width) {
      min-height: $__mobile_width;
      .home-bg {
        min-height: calc(100vw * 0.594);
      }
    }
  }
  &-logo {
    position: absolute;
    right: 0;
    top: 47px;
    width: 512px;
    height: 512px;
  }
  &-content {
    width: 100%;
    z-index: 2;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__button {
      margin-top: 34px;
    }
    &__feat {
      position: relative;
      margin: 0 auto;
      max-width: 1280px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__logo {
      width: 88px;
      height: 88px;
    }
    .des-start,
    .des-end {
      @include __a-font-h1-ali;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    }
    .des-brand {
      @include __a-font-h1-brand;
      margin: 0 12px;
    }
  }
  .module-register-content__tags {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .register-tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    user-select: none;
    &-blue {
      color: $__blue;
      .register-tag-ellipse {
        background-color: $__blue;
      }
    }
    &-green {
      color: $__green;
      .register-tag-ellipse {
        background-color: $__green;
      }
    }
    &-yellow {
      color: $__yellow;
      .register-tag-ellipse {
        background-color: $__yellow;
      }
    }

    &-red {
      color: $__red;
      .register-tag-ellipse {
        background-color: $__red;
      }
    }
    &-ellipse {
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
    &-text {
      @include __a-font-h4-brand;
    }
  }
}

@media screen and (max-width: $__mobile_width) {
  .module-register {
    &-logo {
      top: 11px;
      width: 126px;
      height: 126px;
    }

    &-content {
      margin-top: 35px;
      &__logo {
        width: 44px;
        height: 44px;
      }

      .des-start,
      .des-end {
        font-weight: 300;
        font-size: 24px;
        line-height: 34px;
      }
      .des-brand {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin: 0 6px;
      }

      .module-register-content__tags {
        gap: 5px;
      }
      .register-tag {
        gap: 2px;
        &-ellipse {
          width: 2px;
          height: 2px;
        }
        &-text {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
        }
      }

      &__button {
        margin-top: 14px;
      }

      &__feat {
        padding-top: 30px;
        margin: 0;
        width: 100%;
        .page-module-img {
          //margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .mobile {
    .module-register {
      .module-register-content__feat__bubbles {
        display: none;
      }
      .page-module-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .mobile {
    .module-register {
      .module-register-content__feat__bubbles {
        transform: scale(0.8);
        transform-origin: center top;
      }
      .page-module-img {
        width: 80%;
        height: 80%;
      }
    }
  }
}
