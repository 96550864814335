@import '@/theme/variables.scss';

.feat-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.feat-cards {
  width: 100%;
}
.feat-card {
  box-sizing: border-box;
  width: 302px;
  height: 230px;

  background: linear-gradient(180deg, #181818 0%, #090909 100%);
  /* Black#333 */

  border: 1px solid #333333;
  /* Shadow02 */

  box-shadow: 0 6px 100px rgba(0, 0, 0, 0.8);
  border-radius: 10px;

  padding: 34px;
  &-icon {
    width: 32px;
    height: 32px;

    border-radius: 40px;
    margin-bottom: 20px;
  }
  &-title {
    @include __a-font-h5;
    margin-bottom: 8px;
  }
  &-des {
    @include __a-font-h6;
    color: $__black6;
  }
}

@media screen and (max-width: 1300px) {
  .feat-card-wrapper {
    padding: 20px;
    gap: 20px;
  }
  .feat-card {
    width: calc(50% - 10px);
  }
}

@media screen and (max-width: $__mobile_width) {
  .feat-card-wrapper {
    padding: 20px;
    gap: 16px;
  }
  .feat-card {
    width: calc(50% - 8px);
    height: unset;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: transparent;
    border: none;
    overflow: hidden;
    box-shadow: none;
    &-icon {
      width: 23px;
      height: 23px;
      margin-bottom: 8px;
      flex: 0 0 auto;
    }
    &-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 4px;
    }
    &-des {
      text-align: center;
      font-size: 12px;
      line-height: 17px;
    }
  }
}

@media screen and (min-width: $__mobile_width) {
}
