@import '@/theme/variables.scss';

.c-global-nav__wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: $__nav_height;
  background: $__black;
  border-bottom: 0.5px solid $__black3;
  box-sizing: border-box;

}
.c-global-nav {
  height: $__nav_height;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  &-left {
    cursor: pointer;
  }

  .nav-management-btn {
    // 覆盖a标签
    a {
      font-size: 12px;
      line-height: 17px;
      color: #FFFFFF;
      font-weight: 400;
      text-decoration: none;
    }

    border: 1px solid #FFFFFF;
    border-radius: 34px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    cursor: pointer;
    box-sizing: border-box;
  }
  .nav-lang-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2px;
  }
  .nav-lang-btn {
    box-sizing: border-box;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    border-radius: 34px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    line-height: 20px;
    &.active {
      border-color: $__white;
    }
  }
}
@media screen and (max-width: $__mobile_width) {
  .c-global-nav__wrapper {
    height: $__mobile_nav_height;
  }
  .c-global-nav {
    height: $__mobile_nav_height;
    padding: 0 8px 0 16px;
  }
}
